import React from "react"
import Slide from "./index.js"
class MultipleChoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: !this.props.questions || !this.props.questions.length,
      answers: Array(this.props.questions.length).fill(null)
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  answerQuestion(questionIndex, answerValue) {
    const answers = this.state.answers
    answers[questionIndex] = answerValue
    let active = true
    answers.forEach((a) => {
      active = active && a
    })
    this.setState({
      answers,
      active
    })
  }
  handleSubmit(e) {
    if (!this.props.onSubmit) {
      return
    }
    e.data = e.data ? e.data : {}
    e.data.values = this.props.questions.map((q, idx) => {
      return {
        key: q && q.key ? q.key : q.title,
        value: this.state.answers[idx]
      }
    })
    this.props.onSubmit(e)
  }
  render() {
    return (
      <Slide active={this.state.active} className={`cSlide--multiple-choices ${this.props.className || ''}`} {...this.props} onSubmit={(e) => this.handleSubmit(e)}>
        {this.props.questions.map((q, idx) => {
          return (
            <div className="cSlideQuestion" key={idx}>
              <div className="cSlideQuestion-title">{q.title}</div>
              <div className="cSlideQuestion-answers">
                {q.values.map((v, vIdx) => {
                  return (
                    <button className={`cSlideQuestion-answer ${this.state.answers[idx] === v ? 'active' : ''}`} key={vIdx} onClick={() => {
                      this.answerQuestion(idx, v)
                    }}><span>{ v }</span></button>
                  )
                })}
              </div>
            </div>
          )
        })}
      </Slide>
    )
  }
}

export default MultipleChoice
