import React from "react"
import './scss/index.scss';
import Slide from './components/Slide'
import MultipleChoice from './components/Slide/MultipleChoice'
import FitLogic from './helpers/FitLogic'
class App extends React.Component {
  constructor(props) {
    super(props)
    this.setupSlides()
    this.state = {
      subscribed: false,
      selectedSizes: {
        cup: null,
        band: null
      },
      currentSlide: 0
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNextSlide = this.handleNextSlide.bind(this)
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this)
  }
  handleSubmit(e) {
    const values = e && e.data && e.data.values ? e.data.values : []
    const newState = {}
    const nextSlide = this.state.currentSlide >= (this.slides.length - 1) ? this.slides.length - 1 : this.state.currentSlide + 1
    values.forEach((v) => {
      newState[v.key] = v.value
    })
    this.setState({
      selectedSizes: newState,
      currentSlide: nextSlide
    })
  }
  handleNextSlide() {
    const nextSlide = this.state.currentSlide >= (this.slides.length - 1) ? this.slides.length - 1 : this.state.currentSlide + 1
    this.setState({
      currentSlide: nextSlide,
      selectedSizes: {
        cup: null,
        band: null
      }
    })
  }
  handleCancel() {
    const prevSlide = this.state.currentSlide <= 0 ? 0 : this.state.currentSlide - 1
    this.setState({
      currentSlide: prevSlide
    })
  }
  reset() {
    this.setState({
      email: '',
      currentSlide: 0,
      subscribed: false
    })
    if (this.resetTimer) {
      clearTimeout(this.resetTimer)
    }
  }
  setupSlides() {
    const questions = [
      {
        title: 'Band',
        key: 'band',
        values: [32, 34, 36, 38, 40, 42]
      },
      {
        title: 'Cup Size',
        key: 'cup',
        values: ['A', 'B', 'C', 'D', 'DD', 'DDD/E', 'F', 'G']
      }
    ]
    this.slides = []
    this.slides.push(<Slide title="Let's find your Knix bra size" text="New to Knix? Our bra sizing works a little differently" className="cSlide--intro" cancellable={false} nextText="Start" onSubmit={() => {
      this.setState({
        currentSlide: this.state.currentSlide + 1
      })
    }}></Slide>)
    this.slides.push(
      (<MultipleChoice
        subtitle="What size is your best-fitting bra?"
        cancellable={false}
        // sizeNotAvailable={["If you don't see your band and/or cup size here, we are working on a size for you. ", <span>Click here</span>, " to sign up for our emails to stay up to date!"]}
        questions={questions}
        onSubmit={(e) => this.handleSubmit(e)}
        onCancel={() => this.handleCancel()}
        onClick={() => this.handleNextSlide()}
      />)
    )
    this.slides.push((<Slide />))
  }
  handleEmailSubmit() {
    const sizeValue = FitLogic.getSize(this.state.selectedSizes.band, this.state.selectedSizes.cup)
    this.setState(({
      subscribed: true
    }))
    this.resetTimer = setTimeout(() => {
      this.reset()
    }, 15000)
    if (window && window._learnq) {
      window._learnq.push(['identify', {
        '$email': this.state.email,
        'isFromNordstromIpad': true,
        cupSize: this.state.selectedSizes.cup || '??',
        bandSize: this.state.selectedSizes.band || '??',
        ipadDeterminedSize: sizeValue,
        '$source': 'Partnership',
        '$campaign': 'Nordstrom',
        '$medium': 'iPad App'
      }])
      window._learnq.push(['track', 'Completed iPad Fit Quiz', {
        answers: this.state.selectedSizes,
        result: sizeValue
      }]);
    }
  }
  render() {
    const sizeValue = FitLogic.getSize(this.state.selectedSizes.band, this.state.selectedSizes.cup)
    const resultsSlide = (
      <Slide
        active={!!this.state.email && this.state.email.indexOf('@') > 0}
        className="cSlide--result"
        title={sizeValue ? `You're a size ${sizeValue}!` : "We don't yet have a Knix size for you"}
        // text={sizeValue ? 'Sign up for our email to be the first to find out about new colours, launches and products!' : 'Sign up for our email to be the first to find out when new products and sizes launch.'}
        cancelText={"Back"}
        nextable={false}
        // nextText="Subscribe"
        // onSubmit={() => this.handleEmailSubmit()}
        onCancel={()=>{
          this.reset()
      }}>
        {/* <input type="email" placeholder="jane.doe.smith@gmail.com" autoComplete="off" className="cSlide-email" onKeyUp={((e) => e.keyCode === 13 && this.handleEmailSubmit()) || true} onChange={(e) => {
          this.setState({
            email: e.currentTarget.value
          })
        }} /> */}
      </Slide>
    )
    const thankYouSlide = (<Slide title="You're subscribed!" text="We'll notify you when we have some new and exciting stuff launching!" cancelText="Back to the quiz" cancellable={true} nextable={false} onCancel={() => {
      this.reset()
    }} />)
    if (this.state.currentSlide === this.slides.length - 1) {
      this.resetTimer = setTimeout(() => {
        this.reset()
      }, 15000)
    }
    return (
      <div className="App">
        {this.state.currentSlide === this.slides.length - 1 ? (this.state.subscribed ? thankYouSlide : resultsSlide) : this.slides[this.state.currentSlide]}
      </div>
    )
  }
}

export default App
