import React from "react"
import Button from './../Button.js'
const mockCallback = () => {}
class Slide extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      })
    }, 100)
    
  }
  render() {
    const defaults = {
      cancellable: true,
      cancelText: 'Cancel',
      nextable: true,
      nextText: 'Next',
      onSubmit: mockCallback,
      onCancel: mockCallback,
      active: true
    }
    const props = Object.assign({}, defaults, this.props)
    return (
      <div className={`cSlide ${this.state.loaded ? 'loaded' : ''} ${props.className || '' }`}>
        <div className="cSlide-content">
          {props.title ? (<div className="cSlide-title">{props.title}</div>) : null}
          {props.subtitle ? (<div className="cSlide-subtitle">{props.subtitle}</div>) : null}
          {props.text ? (<div className="cSlide-text">{props.text}</div>) : null}
          {this.props.children ? <div className="cSlide-children">{this.props.children}</div> : null}
          {props.cancellable || props.nextable || props.sizeNotAvailable ? (
            <div className="cSlide-buttons">
              { props.nextable ? <Button flavor="primary" onClick={props.onSubmit} className={props.active ? '' : 'disabled'}>{ props.nextText }</Button> : null }
              { props.cancellable ? <Button flavor="link" onClick={props.onCancel}>{ props.cancelText }</Button> : null }
              { props.sizeNotAvailable ? <Button onClick={props.onClick} className="cSlide-info">{this.props.sizeNotAvailable}</Button> : null }
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default Slide
