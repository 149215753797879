const sizeMap = {
  "32A":"1",
  "32B":"1",
  "32C":"2",
  "32D":"4",
  "32DD":"6",
  "32E":"6",
  "32F":"6+",
  "32G":"6+",
  "34A":"1",
  "34B":"2",
  "34C":"4",
  "34D":"4",
  "34DD":"6",
  "34E":"6",
  "34F":"6+",
  "34G":"6+",
  "36A":"2",
  "36B":"3",
  "36C":"3",
  "36D":"5",
  "36DD":"7",
  "36E":"7",
  "36F":"7+",
  "36G":"7+",
  "38B":"3",
  "38C":"5",
  "38D":"5",
  "38DD":"7",
  "38E":"7",
  "38F":"7+",
  "38G":"7+",
  "40C":"5",
  "40D":"8",
  "40DD":"8",
  "40E":"8",
  "40F":"8+",
  "40G":"8+",
  "42D":"8",
  "42DD":"8",
  "42E":"8",
  "42F":"8+",
  "42G":"8+",
  "32DDD/E": "6",
  "34DDD/E": "6",
  "36DDD/E": "7",
  "38DDD/E": "7",
  "40DDD/E": "8",
  "42DDD/E": "8",
}
const FitLogic = {
  getSize: (band, cup) => {
    const value = `${band}${cup}`
    return sizeMap && sizeMap[value] ? sizeMap[value] : null
  }
}
export default FitLogic