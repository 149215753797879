import React from "react"

class Button extends React.Component {
  render() {
    return (
      <button onClick={this.props.onClick} data-flavor={this.props.flavor} className={`cButton ${this.props.className}`}>{this.props.children}</button>
    )
  }
}

export default Button